<template>
  <div>
    <div class="container">
      <div id="map" style="width:100%;height:100%"></div>
      <div class="btnmode" @click="showLayer">
        <span v-if="satelliteMode">标准</span>
        <span v-else>卫星</span>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawer"
      :direction="'btt'"
      :title="title"
      :modal="false"
      destroy-on-close
      size="60%"
    >
      <div class="imgboxwrap">
        <img
          v-for="(item,index) in imgShow"
          :key="index"
          :src="item"
          class="imgbox"
          alt
          @click="dialogVisible=true"
        />
      </div>
      <div class="introbox">
        <table>
          <tr @click="nav()">
            <td width="80px">类别</td>
            <td>{{sight.typeName}}</td>
          </tr>
          <tr>
            <td>今日门票</td>
            <td>
              <div v-if="sight.price>0">{{sight.price}}元</div>
              <div v-else style="color:mediumseagreen">免费</div>
            </td>
          </tr>
          <tr>
            <td>自驾车</td>
            <td>{{sight.carInName}}</td>
          </tr>
          <tr>
            <td>开放时间</td>
            <td>{{sight.start}} ~ {{sight.end}}</td>
          </tr>
          <tr>
            <td>介绍</td>
            <td>{{sight.intro}}</td>
          </tr>
        </table>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="dialogVisible" width="100%" top="2vh" destroy-on-close>
      <el-carousel height="100vh">
        <el-carousel-item v-for="item in imgs" :key="item">
          <div class="picbox">
            <img :src="item" alt class="picstyle" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>
<script>
import { getSightsInMapView, getSightBasic } from "../api/apiv4";
let map, satellite;
var AMap = window.AMap;
var labelsLayer = new AMap.LabelsLayer({
  zooms: [3, 20],
  zIndex: 1000,
  // 该层内标注是否避让
  collision: false,
  // 设置 allowCollision：true，可以让标注避让用户的标注
  allowCollision: true
});

export default {
  data() {
    return {
      markers: [],
      lbMarkers: [],
      sights: [],
      satelliteMode: false,
      latlng: {
        lat1: 0,
        lat2: 0,
        lng1: 0,
        lng2: 0
      },
      imgShow: [],
      title: "",
      drawer: false,
      points: [],
      sight: {},
      dialogVisible: false,
      dataObj: "",
      logoSrc: require("../assets/logojpg.jpg"),
      store: {},
      stores: [],
      imgs: [],
      latitude: "",
      longitude: ""
    };
  },

  created() {
    this.latitude = this.getQueryVariable("latitude");
    this.longitude = this.getQueryVariable("longitude");
  },
  mounted() {
    this.initMap();
  },
  methods: {
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    initMap() {
      const that = this;
      map = new AMap.Map("map", {
        zoom: 11, //设置地图显示的缩放级别
        mapStyle: "amap://styles/whitesmoke", //设置地图的显示样式
        center: [that.longitude, that.latitude],
        layers: [
          new AMap.createDefaultLayer(),
          // 卫星
          // new AMap.TileLayer.Satellite(),
          // // 路网
          // new AMap.TileLayer.RoadNet(),
          new AMap.TileLayer.Traffic({
            zIndex: 10,
            zooms: [7, 22]
          })
        ]
      });

      satellite = new window.AMap.TileLayer.Satellite({ zoom: 14 });

      map.on("moveend", function() {
        that.getBoundsFn();
      });
      map.on("zoomend", function() {
        that.getBoundsFn();
      });
      map.on("complete", function() {
        that.getBoundsFn();
      });
    },

    getBoundsFn() {
      var res = map.getBounds();
      let newlatlng = {
        lat1: res.southWest.lat,
        lat2: res.northEast.lat,
        lng1: res.southWest.lng,
        lng2: res.northEast.lng
      };
      if (
        (newlatlng.lat1 < this.latlng.lat1) |
        (newlatlng.lng1 < this.latlng.lng1) |
        (newlatlng.lat2 > this.latlng.lat2) |
        (newlatlng.lng2 > this.latlng.lng2)
      ) {
        this.latlng = newlatlng;
        this.getSight();
      }
    },
    getSight() {
      const that = this;
      let latlng = that.latlng;
      latlng.type = -1;
      getSightsInMapView(latlng).then(res => {
        that.markers = res.data.markers;
        that.sights = res.data.sights;
        that.setMarkerSight(res.data.markers);
      });
    },
    setMarkerSight(datas) {
      const that = this;
      let lbMarkers = [];
      datas.forEach(element => {
        // let img = require("@/assets/sighticon0.png");
        var text = {
          // 要展示的文字内容
          content: element.label.content,
          // 文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
          direction: "bottom",
          // 在 direction 基础上的偏移量
          offset: [0, -5],
          // 文字样式
          style: {
            // 字体大小
            fontSize: 11,
            // 字体颜色
            fillColor: "#22886f",
            // 描边颜色
            strokeColor: "#fff",
            // 描边宽度
            strokeWidth: 2
          }
        };
        let iconPath = element.iconPath.replace("https://server.solar960.com/ast", "");
        let image = require("@/assets" + iconPath);
        var icon = {
          // 图标类型，现阶段只支持 image 类型
          type: "image",
          // 图片 url
          image: image,
          // 图片尺寸
          size: [20, 20]
          // 图片相对 position 的锚点，默认为 bottom-center
        };
        var labelMarker = new AMap.LabelMarker({
          name: element.id, // 此属性非绘制文字内容，仅最为标识使用
          position: [element.longitude, element.latitude],
          zIndex: 16,
          // 将第一步创建的 icon 对象传给 icon 属性
          icon: icon,
          // 将第二步创建的 text 对象传给 text 属性
          text: text
        });
        labelMarker.on("click", function(e) {
          that.onMarkerClick(e.target._opts.name);
        });
        lbMarkers.push(labelMarker);
      });
      this.lbMarkers = lbMarkers;
      labelsLayer.add(lbMarkers);
      map.add(labelsLayer);
    },

    onMarkerClick(id) {
      const that = this;
      let index = this.sights.findIndex(item => {
        return item.id == id;
      });
      getSightBasic({
        id: that.sights[index].id
      }).then(res => {
        res.data.imgs = that.sights[index].imgs;
        res.data.latitude = that.sights[index].latitude;
        res.data.longitude = that.sights[index].longitude;
        that.sight = res.data;
        let imgs = []; //大图
        res.data.imgs.forEach(element => {
          imgs.push(element.replace("_thumb", ""));
        });
        that.imgs = imgs;
        this.imgShow = res.data.imgs.slice(0, 3);
        that.openDrawer(res.data.name);
        that.dataObj =
          "https://server.solar960.com/qrtowechat/sightdetail/" + res.data.id;
      });
    },
    showLayer() {
      if (this.satelliteMode) {
        map.removeLayer(satellite);
        this.satelliteMode = false;
      } else {
        this.satelliteMode = true;
        map.addLayer(satellite);
      }
    },
    nav() {
      window.open(
        `http://uri.amap.com/marker?position=${this.sight.longitude},${this.sight.latitude}&name=${this.sight.name}&coordinate=gaode&callnative=1`
      );
    },

    openDrawer(title) {
      this.title = title;
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    },

    handlePicClose() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped>
.container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100vh;
  position: relative;
}

.btnmode {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px 10px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  box-shadow: 0 0 5px #999;
}

.el-drawer__body {
  overflow: auto;
}

.imgboxwrap {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.imgbox {
  width: 30%;
  height: 90px;
  border-radius: 10px;
  border: 1px solid #eee;
}
.introbox {
  width: 95%;
  margin: 5px auto;
  padding: 10px;
  border-top: 1px dashed #ccc;
}

table tr :first-child {
  color: #777;
}

table td {
  padding: 5px 0;
  vertical-align: top;
}

.picbox {
  display: flex;
  justify-content: center;
  height: 400px;
}
.picstyle {
  height: 500px;
  border-radius: 10px;
  max-width: 80%;
}
</style>